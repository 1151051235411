import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './cheatsheet.module.css'
import Layout from '../../vscodevimbook/Layout'
import SEO from '../../components/seo'

export default class Cheatsheet extends React.Component {
  render() {
    console.log(this.props)
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <article className={styles.page}>
          <SEO
            title={`Boost Your Coding Fu With VSCode and Vim - Cheatsheet | ${siteTitle}`}
          />
          <section className={styles.content}>
            <h1>Cheatsheet</h1>
            <KeyCategories categories={categories} />
            <footer>
              {
                // TODO: Add references to more cheatsheets
              }
            </footer>
          </section>
        </article>
      </Layout>
    )
  }
}

const KeyCategories = ({ categories }) => (
  <section className={styles.categories}>
    {categories.map((c) => (
      <KeyCategory category={c} />
    ))}
  </section>
)
const KeyCategory = ({ category }) => (
  <section className={styles.category}>
    <h2 className={styles.categoryheader}>{category.name}</h2>
    <Keys keys={category.keys} />
  </section>
)
const Keys = ({ keys }) => (
  <ul>
    {keys.map((k) =>
      k instanceof Separator ? (
        <div className={styles.separator}>{k.title}</div>
      ) : (
        <li>
          {k.keys.map((k) => (
            <code className="language-text" style={{ marginRight: '3px' }}>
              {k}
            </code>
          ))}{' '}
          <span>{k.description}</span>
        </li>
      )
    )}
  </ul>
)

class Separator {
  constructor(title) {
    this.title = title
  }
}

const categories = [
  Category('Baby Steps', [
    Key('h', 'move cursor to the left'),
    Key('j', 'move down'),
    Key('k', 'move up'),
    Key('l', 'move right'),
    Key('i', 'Go into Insert mode'),
    Key(['<ESC>', '<C-C>', '<C-[>'], 'Go back to Normal mode'),
  ]),

  Category('Move Fast Word by Word', [
    Key('w', 'move to the beginning of next word'),
    Key('b', 'move to the beginning of the previous word'),
    Key('e', 'move to the end of the next word'),
    Key('ge', 'move to the end of the previous word'),
    new Separator(),
    Key('W', 'move to the beginning of next WORD'),
    Key('B', 'move to the beginning of the previous WORD'),
    Key('E', 'move to the end of the next WORD'),
    Key('gE', 'move to the end of the previous WORD'),
  ]),

  Category('Find Character', [
    Key('f{character}', 'Find next occurrence of character'),
    Key('F{character}', 'Find previous occurrence of character'),
    new Separator(),
    Key(
      't{character}',
      'Find next occurrence of character and place cursor just before it'
    ),
    Key(
      'T{character}',
      'Find previous occurrence of character and place cursor just before it'
    ),
    new Separator(),
    Key(';', 'Go to next occurrence of {character}'),
    Key(',', 'Go to previous occurrence of {character}'),
  ]),

  Category('Move Extremely Horizontally', [
    Key('0', 'Moves to the first character of a line'),
    Key('^', 'Moves to the first non-blank character of a line'),
    Key('$', 'Moves to the end of a line'),
    Key('g_', 'Moves to the non-blank character at the end of a line'),
  ]),

  Category('Move Faster Vertically', [
    Key('}', 'Jumps entire paragraphs downwards'),
    Key('{', 'similarly but upwards'),
    Key('CTRL-D', 'lets you move down half a page by scrolling the page'),
    Key('CTRL-U', 'lets you move up half a page also by scrolling'),
  ]),

  Category('High Precision Vertical Motions with Search', [
    Key('/{pattern}', 'Search for {pattern}. {pattern} is a regex.'),
    Key('?{pattern}', 'Search for {pattern} backwards.'),
    new Separator(),
    Key('/', 'Repeat last search forwards'),
    Key('?', 'Repeat last search backwards'),
    new Separator(),
    Key('n', 'Go to next match'),
    Key('N', 'Go to previous match'),
  ]),

  Category('Move Faster with Counts', [
    Key('{count}{motion}', 'Repeat {motion} {count} times'),
    new Separator(),
    Key('2w', 'Jump to second word'),
    Key('4f"', 'Jump to fourth occurrence of the " character'),
    Key('3/cucumber', 'Jump to third match of "cucumber"'),
  ]),

  Category('Move Semantically', [
    Key('gd', 'Go to definition (of the word under the cursor)'),
    Key('gf', 'Go to file (for file under the cursor)'),
  ]),

  Category('More Nifty Core Motions', [
    Key('gg', 'Go to the top of the file'),
    Key('{line}gg', 'Go to {line}'),
    Key('G', 'Go to the end of the file'),
    Key('%', 'jump to matching ({[]})'),
  ]),

  Category('Edit Like Magic With Vim Operators', [
    Key(
      '{operator}{count}{motion}',
      'Apply operator on bit of text covered by motion'
    ),
    new Separator(),
    Key('d', 'delete'),
    Key('c', 'change'),
    Key('y', 'yank (copy)'),
    Key('p', 'p (paste)'),
    Key('g~', 'switch case'),
    Key('>', 'shift right'),
    Key('<', 'shift left'),
    Key('=', 'format'),
  ]),

  Category('Linewise Operators', [
    Key('dd', 'delete a line'),
    Key('cc', 'change a line'),
    Key('yy', 'yank (copy) a line'),
    Key('g~~', 'switch case of a line'),
    Key('>>', 'shift line right'),
    Key('<<', 'shift lineleft'),
    Key('==', 'format line'),
  ]),

  Category('Capital case (Stronger version) Operators', [
    Key('D', 'delete from cursor to the end of the line'),
    Key('C', 'change from cursor to the end of the line'),
    Key('Y', 'yank (copy) a line. Like yy'),
    Key('P', 'put (paste) before the cursor'),
  ]),

  Category('Text objects', [
    Key(
      '{operator}a{text-object}',
      'Apply operator to all text-object including trailing whitespace'
    ),
    Key('{operator}i{text-object}', 'Apply operator inside text-object'),
    new Separator(),
    Key('diw', 'delete inner word'),
    Key('daw', 'delete a word'),
    Key('dis', 'delete inner sentence'),
    Key('das', 'delete a sentence'),
    Key('dip', 'delete inner paragraph'),
    Key('dap', 'delete a paragraph'),
    Key(['di(', 'dib'], 'delete inside parentheses'),
    Key(
      ['da(', 'dab'],
      'delete text inside parentheses (including parentheses)'
    ),
    Key(['di{', 'diB'], 'delete inside braces'),
    Key(['da{', 'daB'], 'delete text inside braces (including braces)'),
    Key('di[', 'delete inside brackets'),
    Key('da[', 'delete text inside brackets (including brackets)'),
    Key('di"', 'delete inside quotes'),
    Key('da"', 'delete a quoted text (including quotes)'),
    Key('dit', 'delete inside tag'),
    Key('dat', 'delete a tag (including tag)'),
    new Separator(),
    Key('ciw', 'same goes for other operators...'),
  ]),
  // HERE

  Category('Repeat Last Change', [Key('.', 'Repeat the last change')]),

  Category('Character Editing Commands', [
    Key('x', 'delete a character. Like dl'),
    Key('X', 'delete character before the cursor. Like dh'),
    Key('s', 'change a character. Like cl'),
    Key('~', 'switch case of a character'),
  ]),

  Category('Undo and redo', [
    Key('u', 'undo last change'),
    Key('C-R', 'redo last undo'),
    Key('{count}u', 'undo last {count} changes'),
  ]),

  Category('Inserting Text', [
    Key('i', 'go into insert mode before the cursor'),
    Key('a', 'go into insert mode after the cursor'),
    new Separator(),
    Key('I', 'go into insert mode at the beginning of a line'),
    Key('A', 'go into insert mode at the end of a line'),
    new Separator(),
    Key('o', 'insert new line below current line and go into insert mode'),
    Key('O', 'insert new line above current line and go into insert mode'),
    new Separator(),
    Key('gi', 'go to the last place you left insert mode'),
    new Separator(),
    Key('C-H', 'delete last character'),
    Key('C-W', 'delete last word'),
    Key('C-U', 'delete last line'),
  ]),

  Category('Visual Mode', [
    Key('v', 'go into character-wise visual mode'),
    Key('V', 'go into line-wise visual mode'),
    Key(
      'C-V',
      'go into block-wise visual mode (to select rectangular blocks of text)'
    ),
    new Separator(),
    Key(
      '{trigger visual mode}{motion}{operator}',
      'Visual mode operates in kind of the opposite way to normal mode. First you specify the motion to select text, and then you apply the operator'
    ),
  ]),

  Category('Operate on Next Search Match', [
    Key('{operator}gn', 'Apply operator on next match'),
    new Separator(),
    Key(
      '.',
      'After using {op}gn, the dot commant repeats the last change on the next match. Woooot!'
    ),
  ]),

  Category('Copying and pasting', [
    Key('y{motion}', 'yank (copy) text covered by motion'),
    Key('p', 'put (paste) after cursor'),
    Key('P', 'paste before cursor'),
    new Separator(),
    Key('yy', 'copy line'),
    Key('Y', 'copy line'),
    new Separator(),
    Key('yyp', 'duplicate line'),
    Key('ddp', 'swap lines'),
    Key('xp', 'swap characters'),
    new Separator(),
    Key('"ay{motion}', 'copy to register a'),
    Key('"Ay{motion}', 'copy and append to register a'),
    Key('"ap', 'paste from register a'),
    new Separator(),
    Key('"', 'unnamed register'),
    Key('0', 'yank register'),
    Key('1-9', 'delete registers'),
    Key('[a-z]', 'named registers'),
    new Separator(),
    Key('C-R a', 'paste from register a when in Insert mode'),
  ]),

  Category('Command-line mode', [
    Key([':edit {file}', ':e {file}'], 'create or edit file'),
    Key([':write', ':w'], 'save file'),
    Key([':quit', ':q'], 'close file'),
    Key([':write!', ':w!'], 'force save file'),
    Key([':quit!', ':q!'], 'close file without saving'),
    Key(':wq', 'save and close file'),
    Key([':wall', ':wa'], 'save all files'),
    Key([':qall', ':qa'], 'close all files'),
    Key([':wqall', ':wqa'], 'save and close all files'),
    Key([':qall!', ':qa!'], 'close all files without saving'),
    new Separator(),
    Key(
      [':[range]delete [register]', ':[r]d [r]'],
      'delete multiple lines into register'
    ),
    new Separator(),
    Key('@:', 'repeat last ex command'),
    Key('@@', 'after repeating it once, you can continue repeating with this'),
  ]),

  Category('Command-line mode Ranges', [
    Key(':{start},{end}', 'start and end lines of range e.g. :1,2d'),
    Key(':{start},{offset}', 'start and offset lines of range e.g. :1,+2d'),
    Key('.', 'current line e.g. :.,+2d'),
    Key('%', 'whole file e.g. :%d'),
    Key('0', 'beginning of file e.g. :0,10d'),
    Key('$', 'end of file e.g. :10,$d'),
    Key(":'<,'>", 'visual selection'),
  ]),

  Category('Command-line mode Substitute', [
    Key(
      ':[range]/{pattern}/{substitute}/[flags]',
      'substitute matched pattern for string literal in given range'
    ),
    new Separator(),
    Key('g flag', 'substitute all matches in a line'),
    Key('i flag', 'case insensitive search'),
    Key('c flag', 'confirm substitution for each match'),
  ]),

  Category('Split Windows', [
    Key(':sp {file}', 'Open file in a horizontal split'),
    Key(':vsp {file}', 'Open file in a vertical split'),
    new Separator(),
    Key('C-W S', 'Open same file in a horizontal split'),
    Key('C-W V', 'Open same file in a vertical split'),
    new Separator(),
    Key('C-W h', 'Move to split to the left'),
    Key('C-W j', 'Move to split below'),
    Key('C-W k', 'Move to split above'),
    Key('C-W l', 'Move to split to the right'),
  ]),

  Category('Tabs', [
    Key(':tabnew {file}', 'Open file in new tab'),
    Key([':tabnext', ':tabn'], 'Jump to next tab'),
    Key([':tabprev', ':tabp'], 'Jump to previous tab'),
    Key([':tabonly', ':tabo'], 'Close all other tabs'),
  ]),

  Category('Vim surround', [
    Key('ds', 'delete surroundings e.g. ds"'),
    Key('cs', 'change surroundings e.g. cs*tem>'),
    Key('ys', 'add surroundings e.g. ysiw"'),
    new Separator(),
    Key('ds"', 'delete surrounding quotes'),
    Key('cs*tem>', 'change surrounding * for the <em> tag'),
    Key('ysiw"', 'surround word under the cursor with quotes'),
    new Separator(),
    Key(
      'S',
      'In visual mode you can select some text, then type S to add surroundings. e.g. Stp> to wrap the selection in a <p> tag'
    ),
  ]),

  Category('Custom mappings (they need to be added to your config)', [
    new Separator('Insert mode'),
    Key('jk', 'Go back to normal mode'),
    new Separator('Normal mode'),
    Key('J', 'Go down faster'),
    Key('K', 'Go up faster'),
    Key('<leader>j', 'Join lines'),
    Key('<leader>/', 'Clear highlighted text'),
    Key('<leader>w', 'Save file'),
    new Separator(),
    Key('<leader>p', 'Open command palette'),
    Key('<leader>t', 'Go to symbol in file'),
    new Separator(),
    Key('<C-H>', 'Move to the window on the left'),
    Key('<C-J>', 'Move to the window below'),
    Key('<C-K>', 'Move to the window above'),
    Key('<C-L>', 'Move to the window on the right'),
    new Separator(),
    Key('<leader>tt', 'Create new tab'),
    Key('<leader>tn', 'Move to next tab'),
    Key('<leader>tp', 'Move to previous tab'),
    Key('<leader>to', 'Close all tabs but the current one'),
  ]),

  Category('Moving Faster with Vim-sneak', [
    Key('s{char}{char}', 'Jump to the next ocurrence of {char}{char}'),
    Key('S{char}{char}', 'Jump to the previous ocurrence of {char}{char}'),
    Key(';', 'Go to next occurrence of {char}{char}'),
    Key(',', 'Go to previous occurrence of {char}{char}'),
    Key(
      '{op}z{char}{char}',
      'Apply operator on text traversed by vim sneak motion'
    ),
  ]),

  Category('Moving Faster with Easymotion', [
    Key('<leader><leader>w', 'start of words'),
    Key('<leader><leader>b', 'start of words backwards'),
    Key(
      '<leader><leader>bdw',
      'start of words everywhere. The bd stands for bidirectional'
    ),
    Key('<leader><leader>e', 'end of words'),
    Key('<leader><leader>ge', 'end of words backwards'),
    Key('<leader><leader>bdw', 'end of words everywhere'),
    Key('<leader><leader>j', 'beginning of lines'),
    Key('<leader><leader>k', 'beginning of lines backwards'),
    Key('<leader><leader>f{char}', 'find character'),
    Key('<leader><leader>F{char}', 'find character backwards'),
    Key('<leader><leader>t{char}', 'until character'),
    Key('<leader><leader>T{char}', 'until character backwards'),
    Key('<leader><leader>s{char}', 'search character everywhere'),
  ]),

  Category('Multiple Cursors', [
    new Separator('Based on Search'),
    Key(
      ['<CMD-D>', '<C-D>', 'gb'],
      'Adds an additional cursor. This command puts you in visual mode. In effect, it selects all the words under each new cursor at once.'
    ),
    Key(
      '{operator}',
      'Applies operator on words selected by the multiple cursors.'
    ),
    Key('I', 'Insert before multiple words at the same time'),
    Key('A', 'Append after multiple words at the same time'),
    new Separator('In Consecutive Rows'),
    Key('<C-V>', 'Go into Visual-block mode and select text using motions'),
    Key('{operator}', 'Applies operator on visual selection.'),
    Key('I', 'Insert before visual selection'),
    Key('A', 'Append after visual selection'),
  ]),

  Category('Reusable Editing with Macros', [
    Key('q{register}', 'Start recording a new macro in {register}'),
    Key('q', 'Stop recording macro'),
    Key('@{register}', 'Replay macro in {register}'),
    Key('@@', 'Replay the last macro that you executed'),
  ]),

  Category('Integrating with Neovim', [
    Key(':[range]copy {address}', 'Copy lines after {address} line'),
    Key(':[range]move {address}', 'Move lines after {address} line'),
    Key(
      ':[range]normal {commands}',
      'Apply normal mode commands on a range of lines'
    ),
    Key(
      ':execute "{ex-command}"',
      'Execute string as an Ex command. Useful in combination with normal when the normal mode commands include special characters.'
    ),
    Key(
      ':[range]global/{pattern}/{command}',
      'Execute an Ex command on lines within a range that match a pattern. The default range is the whole file. Really useful in combination with the normal command.'
    ),
    Key(
      ':[range]global!/{pattern}/{command}',
      'Execute an Ex command on lines within a range that do not match a pattern.'
    ),
  ]),

  Category('Some Handy VSCode Only Mappings', [
    Key('af', 'Select increasingly bigger blocks of text'),
    Key('gh', 'Equivalent to hovering the mouse over a bit of text.'),
  ]),

  Category('Bonus 01: Entire and Arguments Text Objects', [
    Key('dae', 'Delete complete buffer'),
    Key('yae', 'Yank complete buffer'),
    Key('cia', 'Change function argument'),
    Key('daa', 'Delete function argument including separator'),
  ]),
]

// tips:
// - Map CAPSLOCK to Control

function Category(name, keys) {
  return {
    name,
    keys,
  }
}
function Key(keys, description) {
  if (!(keys instanceof Array)) keys = [keys]
  return { keys, description }
}

export const pageQuery = graphql`
  query BoostCodingFuVSCodeVimCheatsheetQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
